.keen-slider {
  display: flex;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.keen-slider, .keen-slider__slide {
  overflow: hidden;
  position: relative;
}

.keen-slider__slide {
  width: 100%;
  min-height: 100%;
}

.keen-slider[data-keen-slider-v] {
  flex-wrap: wrap;
}

.keen-slider[data-keen-slider-v] .keen-slider__slide {
  width: 100%;
}

.keen-slider[data-keen-slider-moves] * {
  pointer-events: none;
}

/*# sourceMappingURL=keen-slider.min.css.map */